/*  所有请求的URL全部在此处配置 */
import { Menu } from '@shared/model/baseModel';
import { environment } from '../../../environments/environment';

export const BASE = environment.BASE;
export const FEEACC = environment.FEEACC;
export const BASEFRONT = environment.BASEFRONT;
// export const BASE = 'http://192.168.0.111:18186'; // 高博林
// export const BASE = 'http://192.168.0.103:18090'; // 王伟鹏
// export const BASE = 'http://192.168.0.111:18095'; // 高博林

export const REQUEST = {
  LOGOUT: '/uaa/logout',
  DISPLAY_PIC_BASE: BASE + '/file?file=',
  UPLOAD_URL: BASE + '/v1/files',
  EXPORT: BASE + '/file?file=',
  WSS: environment.WSS,
  DIC: '/v1/car/manager/bg/error', // 获取下拉菜单列表
  LOGIN: '/uaa/oauth/token',
  USERINFO: '/uaa/oauth/profile',
  clientId: 'lee',
  dummyClientSecret: 'lee_secret',
  USER: {
    ALL: '/v1/users',
    COMPANY: '/v1/companys',
    RECHARGE: '/v1/offer/order/pay/virtualaccount',
    CHECK: '/v1/users/driver/check',
    OPENACC: '/v1/cmbc/member',
    MINGSHENG: '/v1/cmbc/sign'
  },
  ROLE: {
    ALL: '/v1/roles',
    ZONES: '/v1/zones',
    OPERATIONS: '/v1/operations'
  },
  PARKS: {
    ALL: '/v1/parks',
    FULL: '/v1/parks/all'
  },
  INVOICE: {
    ALL: '/v1/invoice/bg/invoices/search',
    DETAIL: '/v1/invoice/detail',
    ANLIAN: '/v1/invoice/anlian',
    DENY: '/v1/invoice/deny',
    PASS: '/v1/invoice/pass',
    INVALID: '/v1/invoice/cancet'
  },
  CAR: {
    ALL: '/v1/car/manager/car',
    REVIEWINFO: '/v1/car/manager/bg/car/',
    REVIEW: '/v1/car/manager/bg/verify/',
    PLATE_NUM_SEARCH: '/v1/car/manager/car/plate_num/',
    SUPER_SEARCH: '/v1/car/manager/cars',
    UNBIND: '/v1/car/manager/company/unbind',
    EDIT: '/v1/car/manager/car/id/',
    UPDATE: '/v1/car/manager/',
    ADD: '/v1/car/manager/car',
    BINDDRIVER: '/v1/car/manager/web/truckstodriver',
    BINDTRAILER: '/v1/car/manager/web/truckstotrucks',
    TRAILERINFO: '/v1/car/manager/web/Gcar',
    SYNCHN: '/v1/car/manager/sync/anlian',
    VERIFY: '/v1/gs/yunyan',
    DIC: '/v1/dict/type'
  },
  CAR_HANGUP: {
    ALL: '/v1/car/manager/company/affli',
    OPERATE: '/v1/car/manager/company/',
    CHECK: '/v1/car/manager/company/affli/info',
  },
  DRIVER: {
    ALL_DRIVERS: '/v1/drivers',
    ALL_ILLEGAL: '/v1/driverillegals',
    ALL: '/v1/drivers/',
    ADD: '/v1/driverillegals/anlian',
    UPDATE: '/v1/driverillegals/',
    REVIEW: '/v1/driverillegals/approvals',
    SYNCTJ: '/v1/driverillegals/sync/tj',
    SYNCHN: '/v1/driverillegals/sync/hn',
    VERIFY: '/v1/gs/yunyan',
    DIC: '/v1/dict/type',
    SYNC: '/v1/drivers/dyDriverDataSync',
    DRIVERINFO: '/v1/drivers/details'
  },
  ORDER: {
    ALL: '/v1/orders/',
    SEARCH: '/v1/offer/order/detials/search',
    VERIFY: '/v1/gs/yunyan',
    SYNC: '/v1/orders/dyOrderDataSync',
    ALLDATA: '/v1/orders/page',
    ORDERINFO: '/v1/orders'
  },
  ETC: {
    ALL: '/v1/etcs',
    REVERT: '/v1/etcs/{id}/state/4',
    BATCH: '/v1/etcs/file',
  },
  OIL: {
    ALL: '/v1/oilcards',
    IMPORT: '/v1/oilcards/file',
    IMPORTBASE: '/v1/oilcards/basefile',
    VALIDATE: '/v1/oilcards/getValidatePic',
    DISTRIBUTION: '/v1/oilcards/company',
    DIC: '/v1/dict/type',
    CHARGE: '/v1/oilcards/charge',
    EXPORT: '/v1/oilcards/export'
  },
  LOG: {
    OILRECHARGE: '/v1/oilcardofferlog',
    BILLING: '/v1/invoice/logs',
    POLICY: '/v1/car/insurance',
    INSURANCE: '/v1/car/insurance/policy/',
    EXPORT: '/v1/car/insurance/policy/export/bg',
    OILUSE: '/v1/oilcards/order',
    OPERATION: '/v1/logs'
  },
  DIST: {
    ALL: '/v1/dispatchcars',
    CAR_TRACK_MAP: '/v1/car/location/gpss/',
    CAR_BDS_MAP: '/v1/car/location/beidous/',
    CAR_TRACK_LIST: '/v1/dispatchcars/getlocus/',
    APPLY_PAY: '/v1/offer/order',
    APPLY_BILL: '/v1/invoice'
  },
  PRE_DIST: {
    ALL: '/v1/orders',
    EDIT_WAYBILL: '/v1/dispatchcars/',
    GET_CODE: '/v1/orders/getordercode/',
    GET_ORDER: '/v1/orders/ordercode/',
    GET_DIST_DETAIL: '/v1/predispatchs/',
    PULL_VERHICAL: '/v1/predispatchs/transport',
    SELECT_CAR: '/v1/car/manager/company',
    GET_DISPATCHCAR: '/v1/dispatchcars/getcodecar/',
    RECEIPT_PIC: '/v1/dispatchcars/carordercode/receiptpic/',
    UNLOADING_PIC: '/v1/dispatchcars/carordercode/unloadingpic/',
    CONTACT_DRIVER: '/v1/predispatchs/createlist',
    FINISH_ORDER: '/v1/dispatchcars/updatestatus/'
  },
  SERVICEFEETYPE: {
    ALL: '/v1/serviceFee/type',
    REMOVE: '/v1/serviceFee/type/del'
  },
  SERVICEFEELIST: {
    ALL: '/v1/serviceFee/statistics'
  },
  SERVICEFEEGROUP: {
    GROUP: '/v1/group',
    LIST: '/v1/serviceFee/group',
    BINDGROUP: '/v1/group/company' // /groupId/companyId
  },
  STATISTICS: {
    ALL: '/v1/report/statistics/order',
    TODAY: '/v1/report/day/info',
    WEEK: '/v1/report/week/info',
    MONTH: '/v1/report/month/info',
    TIME: '/v1/report/time/info',
    RANKINGLIST: '/v1/report/compnay/moneylist'
  },
  ORDERS: {
    ORDER: '/v1/orders/',
    ALL: '/v1/dispatchcars/',
    DIC: '/v1/dict/type',
    HNADDRESS: '/v1/area/hn',
    TJADDRESS: '/v1/area/tj',
    JCADDRESS: '/v1/area/jc',
    ORDEREDIT: '/v1/orders/data',
    WARTERMARK: '/v1/files/watermark',
    DISPATCHEDIT: '/v1/dispatchcars/data',
    EXPORT: '/v1/dispatchcars/export/bg'
  },
  MESSAGE: {
    WSS: 'topic',
    ALL: '/v1/message/unhandle/detail',
    COUNT: '/v1/message/unhandle/count',
    RESOLVE: '/v1/message/handle',
    COMPLAINT: '/v1/message'
  },
  TAX: {
    INDUSTRY: '/v1/order/industry',
    PERSONALL: '/v1/tax/individual',
    PERSONDECLARE: '/v1/tax/individual/declare',
    PERSONGENERATE: '/v1/tax/individual/generate',
    PERSONGEXPORT: '/v1/tax/individual/export',
    SUMALL: '/v1/tax/individual/sum',
    SUMDECLARE: '/v1/tax/individual/sum/declare',
    SUMGENERATE: '/v1/tax/individual/sum/generate',
    SUMGEXPORT: '/v1/tax/individual/sum/export',
    OEDERALL: '/v1/tax/addition',
    OEDERDECLARE: '/v1/tax/addition/declare',
    OEDERGENERATE: '/v1/tax/addition/generate',
    OEDERGEXPORT: '/v1/tax/addition/export',
    PAYEDORDERALL: '/v1/offer/order/already',
    PAYEDORDERALLPRE: '/v1/offer/order/search?offer_type=1&offer_state=1&sync=00060',
    PAYEDORDERSYNC: '/v1/offer/order/sync/tj',
    PAYEDORDERADDPOINT: '/v1/car/location/beidous/save',
    EXPORT: '/v1/offer/order/export',
    OUTPUTALL: '/v1/invoice/push/search',
    OUTPUTSYNC: '/v1/invoice/push/tax',
    OUTOUTGENE: '/v1/invoice/push/push',
    OUTPUTEXPORT: '/v1/invoice/push/export',
    OUTPUTDELETE: '/v1/invoice/push/delete'
  },
  CARFREE: {
    DIC: '/v1/dict/type',
    ELEALL: '/v1/gs/electronicOrders',
    UPLOAD_URL: BASE + '/v1/gs/file',
    ELEINFO: '/v1/gs/electronicOrder',
    // SYNC: '/v1/gs/upload',
    SYNC: '/v1/gs/dataSyn',
    GENE: '/v1/gs/order/generate',
    DELETE: '/v1/gs',
    CAPAll: '/v1/gs/moneyOrders',
    CAPINFO: '/v1/gs/moneyOrder',
    CARALL: '/v1/gs/gsVehicles',
    CARDETAIL: '/v1/gs/gsVehicle',
    DRIVERALL: '/v1/gs/gsDrivers',
    DRIVERDETAIL: '/v1/gs/gsDriver',
    DOWNLOAD: '2020-08-25/'
  },
  VIP: {
    ALL: '/v1/cmbc/members',
    EACH: '/v1/cmbc/member',
    OPENACC: '',
    EDIT: '',
    BINDCARD: '/v1/cmbc/bankCard/bind',
    UNBIND: '/v1/cmbc/bankCard/unbind',
    MINEBANK: '/v1/cmbc/bankCard/mine',
    MINEBANKS: '/v1/cmbc/bankCards',
    TAKEOUTCASH: '/v1/cmbc/platform/transferAccount/withdraw',
    TAKECOMCASH: '/v1/cmbc/withdraw',
    FEECOLLECTION: '/v1/cmbc/expenseWithdrawal',
    BANKDIC: '/v1/area/ms',
    BANKS: '/v1/dict/type',
    BANKCARDEDIT: '/v1/cmbc/bankCard'
  },
  PAYORDER: {
    ALL: '/v1/cmbc/fundsDetailQuery',
    CHARGEBACK: '/v1/cmbc/rollbackTransfer',
    FREEZE: '/v1/cmbc/freeze',
    UNFREEZE: '/v1/cmbc/unfreeze',
    EXPORT: '/v1/cmbc/fundsDetailQuery/export'
  },
  OFFLINE: {
    ALL: ''
  },
  FEEINQUIRY: {
    ALL: '/v1/cmbc/msCharge',
    EXPORT: ''
  },
  CAPFLOW: {
    ALL: '/v1/cmbc/fundsDetailQuery/ms',
    DOWNLOAD: '',
    EXPORT: '',
    GENEACCOUNTS: '/v1/cmbc/sendFileMessage',
    DUIZHANG: '/v1/cmbc/check',
    REACCINFO: '/v1/cmbc/checkInformation'
  },
  BUSFLOW: {
    ALL: '/v1/cmbc/merchantExSerialDetailQuery',
  },
  PEINT: {
    ALL: '/v1/cmbc/clientInfoPortal'
  },
  SIGN: {
    VERCODE: '/v1/mobile/verify',
    ALLACC: '/v1/signature/accounts', //分页获取模板集合
    EACHACC: '/v1/signature/account', //获取单个用户，添加用户并获取证书，更换用户手机号
    ALLCONTRACT: '/v1/signature/contracts', //分页获取模板集合
    EACHCON: '/v1/signature/contract', //生成，撤销，作废，退回，获取单个合同
    CONDOWNLOAD: '/v1/signature/contract/download', //合同下载
    CONIMG: '/v1/signature/contract/img', //获取合同图片
    SIGNOFFLINE: '/v1/signature/contract/offline/sign', //线下签署合同
    SIGNED: '/v1/signature/contract/sign', //签署合同
    SIGNVERFY: '/v1/signature/contract/verify', //合同验真
    ALLSEAL: '/v1/signature/seals', //分页获取签章集合
    EACHSEAL: '/v1/signature/seal', //添加，删除，获取单个签章
    SEALIMG: '/v1/signature/seal/img', //获取签章图片
    ALLTEMP: '/v1/signature/templates', //分页获取模板集合
    EACHTEMP: '/v1/signature/template' //添加，修改，删除，获取单个模板
  },
  PARTNER: {
    ALL: '/v1/companypartners',
    ALLPARTNER: '/v1/companypartners/all',
    TAXOFFICE: '/v1/extern/taxOrgan',
    SYNCTJ: '/v1/companypartners/sync/tj',
    SYNCJC: '/v1/companypartners/sync/jc',
    PARTNEREXPORT: '/v1/companypartners/export'
  },
  UNION: {
    ALL: '/v1/driver/tradeUnion',
  },
  JIMI: {
    ALL: '/v1/car/manager/imei/list',
    BINDCAR: '/v1/car/manager/imei',
    MSGALL: '/v1/car/manager/imei/message/list'
  },
  DRIVERDECLARE: {
    ALL: '/v1/signature/contracts/declare',
    IMG: '/v1/signature/contract/img',
    DOWNLOAD: '/v1/signature/contract/download'

  },

  PARKINFORMATION: {
    ALL: '/v1/companys/page',
    ADD: '/v1/companys/add',
    EDIT: '/v1/companys/edit',
    SYNC: '/v1/companys/syncCompanysData',
    ENTERPRISEINFO: '/v1/companys/details'
  },
  VEHICLE: {
    ALL: '/v1/car/manager/car',
    REVIEWINFO: '/v1/car/manager/bg/car/',
    REVIEW: '/v1/car/manager/bg/verify/',
    PLATE_NUM_SEARCH: '/v1/car/manager/car/plate_num/',
    SUPER_SEARCH: '/v1/car/manager/cars',
    UNBIND: '/v1/car/manager/company/unbind',
    EDIT: '/v1/car/manager/car/id/',
    UPDATE: '/v1/car/manager/',
    ADD: '/v1/car/manager/car',
    BINDDRIVER: '/v1/car/manager/web/truckstodriver',
    BINDTRAILER: '/v1/car/manager/web/truckstotrucks',
    TRAILERINFO: '/v1/car/manager/web/Gcar',
    SYNCHN: '/v1/car/manager/sync/anlian',
    VERIFY: '/v1/gs/yunyan',
    DIC: '/v1/dict/type',
    SYNC: '/v1/car/manager/dyCarDataSync',
    ALLDATA: '/v1/car/manager/page',
    VEHICLEINFO: '/v1/car/manager/details'
  },

  OWNER: {
    ALL: '/v1/companypartners/page',
    ALLPARTNER: '/v1/companypartners/all',
    TAXOFFICE: '/v1/extern/taxOrgan',
    SYNCTJ: '/v1/companypartners/sync/tj',
    SYNCJC: '/v1/companypartners/sync/jc',
    PARTNEREXPORT: '/v1/companypartners/export',
    SYNC: '/v1/companypartners/dyCompanyPartnerDataSync',
    OWERINFO: '/v1/companypartners'
  },
  WAYBILL: {
    ALL: '/v1/dispatchcars/page',
    ALLPARTNER: '/v1/companypartners/all',
    TAXOFFICE: '/v1/extern/taxOrgan',
    SYNCTJ: '/v1/companypartners/sync/tj',
    SYNCJC: '/v1/companypartners/sync/jc',
    PARTNEREXPORT: '/v1/companypartners/export',
    SYNC: '/v1/dispatchcars/syncDispatchCarData',
    WAYBILLINFO: '/v1/dispatchcars',
    SYNCTRACK: '/v1/dispatchcars/syncDispatchCarGPSData'
  },
  FUND: {
    ALL: '/v1/orders/pageMoneyOrder',
    SYNC: '/v1/orders/dyMoneyOrderDataSync',
    FUNDINFO: '/v1/orders/byIdMoneyOrder',
    GENERATE: '/v1/orders/generateMoneyOrder'
  },
  TRACINGPOINT: {
    ALL: '/v1/dispatchcars/syncDispatchCarGPSDianData/page'
  }
};

/*  左侧菜单表格 */
export const Menus: Menu[] = [
  {
    id: 'home',
    name: '首页',
    path: '/home',
    icon: 'file',
    hasSub: false
  },
  {
    id: 'userManagement',
    name: '用户管理',
    path: '/userManagement',
    icon: 'user',
    hasSub: false,
    guard: [100101001]
  },
  {
    id: 'roleManagement',
    name: '角色管理',
    path: '/roleManagement',
    icon: 'safety-certificate',
    hasSub: false,
    guard: [100102001]
  },
  {
    id: 'carManagement',
    name: '车辆管理',
    path: '/carManagement',
    icon: 'car',
    hasSub: false,
    guard: [100200001]
  },
  {
    id: 'driverManagement',
    name: '司机管理',
    path: '/driverManagement',
    icon: 'usergroup-delete',
    hasSub: true,
    guard: [100501000, 100502000],
    subMenus: [
      {
        id: 'unreviewedDriver',
        name: '未审核司机列表',
        path: '/driverManagement/unreviewedDriver',
        icon: 'usergroup-delete',
        hasSub: false,
        guard: [100501000],
      },
      {
        id: 'reviewedDrivers',
        name: '已审核司机列表',
        path: '/driverManagement/reviewedDrivers',
        icon: 'usergroup-delete',
        hasSub: false,
        guard: [100502000],
      }
    ]
  },
  {
    id: 'invoiceManagement',
    name: '发票管理',
    path: '/invoiceManagement',
    icon: 'printer',
    hasSub: false,
    guard: [100300001]
  },
  {
    id: 'oilcardManagement',
    name: '油卡管理',
    path: '/oilcardManagement',
    icon: 'idcard',
    hasSub: false,
    guard: [100400001]
  },
  {
    id: 'orderManagement',
    name: '订单管理',
    path: '/orderManagement',
    icon: 'ordered-list',
    hasSub: false,
    guard: [100800001]
  },
  {
    id: 'messageManagement',
    name: '消息管理',
    path: '/messageManagement',
    icon: 'message',
    hasSub: false,
    guard: [100900001]
  },
  {
    id: 'logManagement',
    name: '日志管理',
    path: '/logManagement',
    icon: 'file-text',
    hasSub: true,
    guard: [100600001, 100600002, 100600003, 100600004, 100600006],
    subMenus: [
      // {
      //   id: 'oilcardRechargeLog',
      //   name: '油卡充值日志',
      //   path: '/logManagement/oilcardRechargeLog',
      //   icon: 'file-text',
      //   hasSub: false,
      //   guard: [100600001],
      // },
      {
        id: 'rechargeLog',
        name: '充值日志',
        path: '/logManagement/rechargeLog',
        icon: 'file-text',
        hasSub: false,
        guard: [100600002],
      },
      {
        id: 'billingLog',
        name: '开票日志',
        path: '/logManagement/billingLog',
        icon: 'file-text',
        hasSub: false,
        guard: [100600003],
      },
      {
        id: 'serviceFeeLog',
        name: '服务费收取日志',
        path: '/logManagement/serviceFeeLog',
        icon: 'file-text',
        hasSub: false,
        guard: [100600004]
      },
      {
        id: 'policyLog',
        name: '保单日志',
        path: '/logManagement/policyLog',
        icon: 'file-text',
        hasSub: false,
        guard: [100600005]
      },
      {
        id: 'oilcardUseLog',
        name: '油卡使用日志',
        path: '/logManagement/oilcardUseLog',
        icon: 'file-text',
        hasSub: false,
        guard: [100600001],
      },
      {
        id: 'userOperationLog',
        name: '用户操作日志',
        path: '/logManagement/userOperationLog',
        icon: 'file-text',
        hasSub: false,
        guard: [100600006],
      },
    ]
  },
  {
    id: 'serviceFeeManagement',
    name: '服务费管理',
    path: '/serviceFeeManagement',
    icon: 'money-collect',
    hasSub: true,
    guard: [100701001, 100702001, 100702008, 100703001],
    subMenus: [
      {
        id: 'serviceFeeList',
        name: '服务费统计',
        path: '/serviceFeeManagement/serviceFeeList',
        icon: 'file-text',
        hasSub: false,
        guard: [100703001]
      },
      {
        id: 'serviceFeeType',
        name: '服务费类型',
        path: '/serviceFeeManagement/serviceFeeType',
        icon: 'file-text',
        hasSub: false,
        guard: [100701001]
      },
      {
        id: 'serviceFeeGroup',
        name: '服务费群组',
        path: '/serviceFeeManagement/serviceFeeGroup',
        icon: 'file-text',
        hasSub: false,
        guard: {
          ability: [100702001, 100702008],
          mode: 'allOf'
        }
      }
    ]
  },
  {
    id: 'taxManagement',
    name: '税务申报管理',
    path: '/taxManagement',
    icon: 'file-text',
    hasSub: true,
    guard: [101001000, 101002000, 101003000, 101004001, 101005001],
    subMenus: [
      {
        id: 'payedOrder',
        name: '已支付订单',
        path: '/taxManagement/payedOrder',
        icon: 'file-text',
        hasSub: false,
        guard: [101004001, 101004002, 101004003],
      },
      {
        id: 'personaltax',
        name: '个税信息',
        path: '/taxManagement/personaltax',
        icon: 'file-text',
        hasSub: false,
        guard: [101001000],
      },
      {
        id: 'personalTotalTax',
        name: '个税汇总',
        path: '/taxManagement/personalTotalTax',
        icon: 'file-text',
        hasSub: false,
        guard: [101002000],
      },
      {
        id: 'otherTax',
        name: '服务方税费',
        path: '/taxManagement/otherTax',
        icon: 'file-text',
        hasSub: false,
        guard: [101003000],
      },
      {
        id: 'outputInvoice',
        name: '销项发票管理',
        path: '/taxManagement/outputInvoice',
        icon: 'file-text',
        hasSub: false,
        // guard: [101003000],
        guard: [101005001, 101005002, 101005003, 101005004],
      }
    ]
  },
  {
    id: 'carFreeManagement',
    name: '无车承运检测管理',
    path: '/carFreeManagement',
    icon: 'file-text',
    hasSub: true,
    guard: [101101000, 101102000, 101103000, 101104000],
    subMenus: [
      {
        id: 'eleTraffic',
        name: '电子运单',
        path: '/carFreeManagement/eleTraffic',
        icon: 'file-text',
        hasSub: false,
        guard: [101101000],
      },
      {
        id: 'capitalFlow',
        name: '资金流水单',
        path: '/carFreeManagement/capitalFlow',
        icon: 'file-text',
        hasSub: false,
        guard: [101102000],
      },
      {
        id: 'carInfoList',
        name: '车辆信息单',
        path: '/carFreeManagement/carInfoList',
        icon: 'file-text',
        hasSub: false,
        guard: [101103000],
      },
      {
        id: 'driverInfoList',
        name: '驾驶员信息单',
        path: '/carFreeManagement/driverInfoList',
        icon: 'file-text',
        hasSub: false,
        guard: [101104000],
      }
    ]
  },
  {
    id: 'parkInformationManagement',
    name: '园区信息管理',
    path: '/parkInformationManagement',
    icon: 'file-text',
    hasSub: true,
    guard: [101801000, 101802000, 101803000, 101804000, 101805000, 101806000, 101807000, 101808000, 101808000],
    subMenus: [
      {
        id: 'enterpriseData',
        name: '企业数据管理',
        path: '/parkInformationManagement/enterpriseData',
        icon: 'file-text',
        hasSub: false,
        guard: [101801000],
      },
      {
        id: 'vehicleData',
        name: '车辆数据管理',
        path: '/parkInformationManagement/vehicleData',
        icon: 'file-text',
        hasSub: false,
        guard: [101802000],
      },
      {
        id: 'driverData',
        name: '司机数据管理',
        path: '/parkInformationManagement/driverData',
        icon: 'file-text',
        hasSub: false,
        guard: [101803000],
      },
      {
        id: 'ownerData',
        name: '货主信息管理',
        path: '/parkInformationManagement/ownerData',
        icon: 'file-text',
        hasSub: false,
        guard: [101804000],
      },

      {
        id: 'orderData',
        name: '订单信息管理',
        path: '/parkInformationManagement/orderData',
        icon: 'file-text',
        hasSub: false,
        guard: [101805000],
      },
      {
        id: 'fundData',
        name: '资金流水单数据管理',
        path: '/parkInformationManagement/fundData',
        icon: 'file-text',
        hasSub: false,
        guard: [101807000],
      },
      {
        id: 'waybillData',
        name: '运单数据管理',
        path: '/parkInformationManagement/waybillData',
        icon: 'file-text',
        hasSub: false,
        guard: [101806000],
      },
      {
        id: 'tracingpointData',
        name: '运单轨迹点数据管理',
        path: '/parkInformationManagement/tracingpointData',
        icon: 'file-text',
        hasSub: false,
        guard: [101808000],
      },
    ]
  },
  {
    id: 'vipManagement',
    name: '账付易管理',
    path: '/vipManagement',
    icon: 'file-text',
    hasSub: true,
    guard: [101201001, 101202001, 101203101, 101203201, 101203301],
    subMenus: [
      {
        id: 'companyVip',
        name: '会员管理',
        path: '/vipManagement/companyVip',
        icon: 'file-text',
        hasSub: false,
        guard: [101201001],
      },
      {
        id: 'payOrderManagement',
        name: '订单管理',
        path: '/vipManagement/payOrderManagement',
        icon: 'file-text',
        hasSub: false,
        guard: [101202001],
      },
      {
        id: 'tradeInquiry',
        name: '交易查询',
        path: '/vipManagement/tradeInquiry',
        icon: 'file-text',
        hasSub: true,
        guard: [101203100, 101203200, 101203300],
        subMenus: [
          {
            id: 'capFlow',
            name: '会员资金流水查询',
            path: '/vipManagement/tradeInquiry/capFlow',
            icon: 'file-text',
            hasSub: false,
            guard: [101203100],
          },
          {
            id: 'merchantCapFlow',
            name: '商户流水明细查询',
            path: '/vipManagement/tradeInquiry/merchantCapFlow',
            icon: 'file-text',
            hasSub: false,
            guard: [101203200],
          },
          // {
          //   id: 'eleBackList',
          //   name: '打印电子回执单',
          //   path: '/vipManagement/tradeInquiry/eleBackList',
          //   icon: 'file-text',
          //   hasSub: false,
          //   guard: [101102000],
          // },
          // {
          //   id: 'offLine',
          //   name: '线下交易查询',
          //   path: '/vipManagement/tradeInquiry/offLine',
          //   icon: 'file-text',
          //   hasSub: false,
          //   guard: [101102000],
          // },
          {
            id: 'feeInquiry',
            name: '费用查询',
            path: '/vipManagement/tradeInquiry/feeInquiry',
            icon: 'file-text',
            hasSub: false,
            guard: [101203301, 101203302],
          }
        ]
      }
    ]
  },
  {
    id: 'conSignManagement',
    name: '易云章管理',
    path: '/carFreeManagement',
    icon: 'file-text',
    hasSub: true,
    guard: [101301000, 101302000, 101303000, 101304000],
    subMenus: [
      // {
      //   id: 'accManage',
      //   name: '用户管理',
      //   path: '/conSignManagement/accManage',
      //   icon: 'file-text',
      //   hasSub: false,
      //   guard: [101101000],
      // },
      {
        id: 'signManage',
        name: '印章管理',
        path: '/conSignManagement/signManage',
        icon: 'file-text',
        hasSub: false,
        guard: [101301000],
      },
      {
        id: 'conManage',
        name: '合同管理',
        path: '/conSignManagement/conManage',
        icon: 'file-text',
        hasSub: false,
        guard: [101302000],
      },
      {
        id: 'declareManage',
        name: '声明管理',
        path: '/conSignManagement/declareManage',
        icon: 'file-text',
        hasSub: false,
        guard: [101304000],
      },
      {
        id: 'tempManage',
        name: '模板管理',
        path: '/conSignManagement/tempManage',
        icon: 'file-text',
        hasSub: false,
        guard: [101303000],
      }
    ]
  },
  {
    id: 'partnerManagement',
    name: '合作伙伴管理',
    path: '/partnerManagement',
    icon: 'user',
    hasSub: false,
    guard: [101400001]
  },
  {
    id: 'unionManagement',
    name: '工会管理',
    path: '/unionManagement',
    icon: 'file-text',
    hasSub: false,
    guard: [101500001]
  },
  {
    id: 'jimiManagement',
    name: '设备管理',
    path: '/jimiManagement',
    icon: 'file-text',
    hasSub: true,
    guard: [101601000, 101602000],
    subMenus: [
      {
        id: 'jimiList',
        name: '设备列表',
        path: '/jimiManagement/jimiList',
        icon: 'file-text',
        hasSub: false,
        guard: [101601000],
      },
      {
        id: 'jimiMsgList',
        name: '设备消息',
        path: '/jimiManagement/jimiMsgList',
        icon: 'file-text',
        hasSub: false,
        guard: [101602000],
      }
    ]
  },
];

export const roleType = [
  { code: 1, value: '管理员' },
  { code: 2, value: '内置' },
  { code: 3, value: '自定义(一般)' },
  { code: 11, value: '维护' }
];

export const carTypeByHangup = [
  { code: '重型仓栅式半挂车', value: '重型仓栅式半挂车' },
  { code: '重型罐式半挂车', value: '重型罐式半挂车' },
  { code: '重型普通半挂车', value: '重型普通半挂车' },
  { code: '重型集装箱半挂车', value: '重型集装箱半挂车' },
  { code: '重型厢式半挂车', value: '重型厢式半挂车' },
  { code: '重型低平板半挂车', value: '重型低平板半挂车' },
  { code: '重型平板半挂车', value: '重型平板半挂车' },
  { code: '重型自卸半挂车', value: '重型自卸半挂车' },
  { code: '重型罐式全挂车', value: '重型罐式全挂车' },
  { code: '重型车辆运输半挂车', value: '重型车辆运输半挂车' }
];
export const carType = [
  { code: '重型普通货车', value: '重型普通货车' },
  { code: '重型厢式货车', value: '重型厢式货车' },
  { code: '重型封闭货车', value: '重型封闭货车' },
  { code: '重型罐式货车', value: '重型罐式货车' },
  { code: '重型平板货车', value: '重型平板货车' },
  { code: '重型集装箱车', value: '重型集装箱车' },
  { code: '重型自卸货车', value: '重型自卸货车' },
  { code: '重型特殊结构货车', value: '重型特殊结构货车' },
  { code: '中型普通货车', value: '中型普通货车' },
  { code: '中型厢式货车', value: '中型厢式货车' },
  { code: '中型封闭货车', value: '中型封闭货车' },
  { code: '中型罐式货车', value: '中型罐式货车' },
  { code: '中型平板货车', value: '中型平板货车' },
  { code: '中型集装箱车', value: '中型集装箱车' },
  { code: '中型自卸货车', value: '中型自卸货车' },
  { code: '中型特殊结构货车', value: '中型特殊结构货车' },
  { code: '轻型普通货车', value: '轻型普通货车' },
  { code: '轻型厢式货车', value: '轻型厢式货车' },
  { code: '轻型封闭货车', value: '轻型封闭货车' },
  { code: '轻型罐式货车', value: '轻型罐式货车' },
  { code: '轻型平板货车', value: '轻型平板货车' },
  { code: '轻型自卸货车', value: '轻型自卸货车' },
  { code: '轻型特殊结构货车', value: '轻型特殊结构货车' },
  { code: '微型普通货车', value: '微型普通货车' },
  { code: '微型厢式货车', value: '微型厢式货车' },
  { code: '微型封闭货车', value: '微型封闭货车' },
  { code: '微型罐式货车', value: '微型罐式货车' },
  { code: '微型自卸货车', value: '微型自卸货车' },
  { code: '微型特殊结构货车', value: '微型特殊结构货车' },
  { code: '低速普通货车', value: '低速普通货车' },
  { code: '低速厢式货车', value: '低速厢式货车' },
  { code: '低速罐式货车', value: '低速罐式货车' },
  { code: '低速自卸货车', value: '低速自卸货车' },
  { code: '重型半挂牵引车', value: '重型半挂牵引车' },
  { code: '中型半挂牵引车', value: '中型半挂牵引车' },
  { code: '轻型半挂牵引车', value: '轻型半挂牵引车' },
  { code: '正三轮载货摩托车', value: '正三轮载货摩托车' },
  { code: '三轮农用运输', value: '三轮农用运输' },
  { code: '四轮农用普通货车', value: '四轮农用普通货车' },
  { code: '四轮农用厢式货车', value: '四轮农用厢式货车' },
  { code: '四轮农用罐式货车', value: '四轮农用罐式货车' },
  { code: '四轮农用自卸货车', value: '四轮农用自卸货车' },
  { code: '大型轮式拖拉机', value: '大型轮式拖拉机' },
  { code: '小型轮式拖拉机', value: '小型轮式拖拉机' },
  { code: '重型普通全挂牵引车', value: '重型普通全挂牵引车' },
  { code: '重型普通半挂牵引车', value: '重型普通半挂牵引车' },
  { code: '重型仓栏式货车', value: '重型仓栏式货车' },
  { code: '轻仓栅式货车', value: '轻仓栅式货车' },
  { code: '轻型专项作业车', value: '轻型专项作业车' },
  { code: '重型车辆运输车', value: '重型车辆运输车' },
  { code: '其他车', value: '其他车' }
];
export const messageTypeValue = [
  { code: [100], value: '开票审核申请' },
  { code: [120], value: '车辆审核申请' },
  { code: [130], value: '司机审核申请' },
  { code: [140], value: '转账通知' },
  { code: [150], value: '投诉消息' }
];

export const messageState = [
  { code: 0, value: '未推送' },
  { code: 1, value: '已推送/未处理' },
  { code: 2, value: '已处理' }
];

export const orderState = [
  { code: 0, value: '未发货' },
  { code: 1, value: '物流公司提货' },
  { code: 2, value: '司机提货' },
  { code: 3, value: '司机卸货' },
  { code: 4, value: '司机签收' },
  { code: 5, value: '司机回单' },
  { code: 6, value: '物流公司签收' }
];


export const ZJCargoType = [
  // { code: '-1', value: '煤炭/钢材/矿石' },
  // { code: '0', value: '普通货物' },
  // { code: '4', value: '易碎品' },
  // { code: '5', value: '生鲜/粮食/化肥' }
  { code: '0', value: '散货' },
  { code: '1', value: '普货' },
  { code: '2', value: '生鲜' },
  { code: '3', value: '易碎' }

];

export const vc = [
  { code: '1', value: '蓝色' },
  { code: '2', value: '黄色' }
];

export const paymentState = [
  { code: 'success', value: '支付成功' },
  { code: 'cancel', value: '取消' },
];

export const invoiceState = [
  { code: 0, value: '已申请', },
  { code: 1, value: '开票中', },
  { code: 2, value: '申请成功', },
  { code: 3, value: '已拒绝', },
];

/* 支付状态 */
export const payState = [
  { code: 0, value: '未支付' },
  { code: 1, value: '预申请' },
  { code: 2, value: '已到账' },
  { code: 3, value: '已申请' },
  { code: 4, value: '已完成' }
];

export const cargoType = [
  { value: '煤炭及制品', code: '0100' },
  { value: '石油、天然气及制品', code: "0200" },
  { value: '金属矿石', code: "0300" },
  { value: '钢铁', code: "0400" },
  { value: '矿建材料', code: "0500" },
  { value: '水泥', code: "0600" },
  { value: '木材', code: "0700" },
  { value: '非金属矿石', code: "0800" },
  { value: '化肥及农药', code: "0900" },
  { value: '盐', code: "1000" },
  { value: '粮食', code: "1100" },
  { value: '机械、设备、电器', code: "1200" },
  { value: '轻工原料及制品', code: "1300" },
  { value: '有色金属', code: "1400" },
  { value: '轻工医药产品', code: "1500" },
  { value: '鲜活农产品', code: "1601" },
  { value: '冷藏冷冻货物', code: "1602" },
  { value: '商品汽车', code: "1701" },
  { value: '其他', code: "1700" }
];

export const freightType = [
  { value: '动物类产品', code: 10 },
  { value: '植物产品', code: 20 },
  { value: '动植物油及制品', code: 30 },
  { value: '烟酒食品饮料', code: 40 },
  { value: '矿产品', code: 50 },
  { value: '化工品', code: 60 },
  { value: '塑料橡胶及制品', code: 70 },
  { value: '皮类及制品', code: 80 },
  { value: '木制及稻草木制品', code: 90 },
  { value: '木浆纤维纸类', code: 100 },
  { value: '纺织制品', code: 110 },
  { value: '鞋帽伞杖鞭及羽毛制品', code: 120 },
  { value: '石料水泥陶瓷玻璃等制品', code: 130 },
  { value: '珠宝首饰', code: 140 },
  { value: '金属及制品', code: 150 },
  { value: '机械电气音响设备及零件', code: 160 },
  { value: '车辆船舶航空器及有关运输设备', code: 170 },
  { value: '精密仪器及设备', code: 180 },
  { value: '武器弹药及零件', code: 190 },
  { value: '杂项制品', code: 200 },
  { value: '艺术品收藏品及古物', code: 210 },
  { value: '特殊交易品及未分类商品', code: 220 }
];

/* 订单类型 */
export const syncType = [
  { code: '00059', value: '河南' },
  { code: '00060', value: '天津' },
  { code: '00554', value: '金昌' }
];

/* 无车承运类型*/
export const areaType = [
  { code: 1, value: '甘肃' },
  { code: 2, value: '天津' },
  { code: 3, value: '河南' },
  { code: 4, value: '金昌' }
];

/*交易类型*/
export const offerType = [
  { code: 1, value: '运单支付' },
  { code: 2, value: '服务费' },
  { code: 3, value: '预充油卡费用' },
  { code: 4, value: '服务费' },
  { code: 5, value: '账户充值' },
  { code: 6, value: '油卡支付' },
  { code: 7, value: '定位费用' },
  { code: 8, value: 'ETC费用' },
  { code: 9, value: '油卡扣款' }
];

/**/
export const workType = [
  { code: 1, value: '平台付款' },
  { code: 2, value: '平台退单' },
  { code: 3, value: '资金解冻' },
  { code: 4, value: '资金冻结' },
  { code: 5, value: '司机提现' },
];

/**/
export const markType = [
  { code: 0, value: '增加' },
  { code: 1, value: '减少' },
  { code: 3, value: '解冻' },
  { code: 2, value: '冻结' }
];

export const businCode = [
  { code: '307', value: '订单支付' },
  { code: '106', value: '资金冻结' },
  { code: '120', value: '还款' },
  { code: '104', value: '提现' },
  { code: '107', value: '资金解冻' },
  { code: '116', value: '平台付款' },
  { code: '306', value: '会员转账' }
];

export const feeCode = [
  { code: '001', value: '充值手续费' },
  { code: '002', value: '提现手续费' },
  { code: '003', value: '服务费' },
  { code: '004', value: '全部类型' }
];

export const bindFlag = [
  { code: '0', value: '未绑定' },
  { code: '1', value: '待绑定' },
  { code: '2', value: '平台代绑定(只能提现)' },
  { code: '3', value: '绑定' },
];
export const accAttr = [
  { code: '2', value: '费用子账簿' },
  { code: '5', value: '风险金子账簿' },
  { code: '6', value: '利息子账簿' },
  { code: '7', value: '市场活动子账簿' },
  { code: 'A', value: '交易资金结算户' },
  { code: 'B', value: '自有资金结算子账簿' },
  { code: 'C', value: '备付金结算子账簿' },
  { code: 'D', value: '中转子账簿' },
  { code: 'E', value: '保证金子账簿' }
];

export const idType = [
  //个人证件类型：
  { code: 1, value: '身份证' },
  { code: 'm', value: '香港通行证' },
  { code: 'n', value: '澳门通行证' },
  { code: 'p', value: '台湾通行证或旅行证件' },
  //机构证件类型：
  { code: 'A', value: '组织机构代码' },
  { code: 'B', value: '营业执照' },
  { code: 'C', value: '社会信用代码' }
];

export const reprIdType = [
  { code: 1, value: '身份证' },
  { code: 'm', value: '香港通行证' },
  { code: 'n', value: '澳门通行证' },
  { code: 'p', value: '台湾通行证或旅行证件' }
];

export const actorIdType = [
  { code: 1, value: '身份证' },
  { code: 9, value: '其他' }
];

export const reAcStatus = [
  { code: 0, value: '未比对' },
  { code: 1, value: '正常' },
  { code: 2, value: '异常' }
];

export const signType = [
  { code: 1, value: '签名' },
  { code: 2, value: '印章' }
];

export const signCardType = [
  { code: '0', value: '身份证' },
  { code: '1', value: '军官证' },
  { code: '2', value: '护照' },
  { code: '3', value: '驾驶证' },
  { code: '4', value: '工商登记证' },
  { code: '5', value: '税务登记证' },
  { code: '6', value: '组织机构代码' },
  { code: '7', value: '其他证件' },
  { code: '8', value: '统一社会信用代码' }
];


export const signAccType = [
  { code: '1', value: '个人' },
  { code: '2', value: '企业' }
];

export const signState = [
  { code: 0, value: '未签' },
  { code: 1, value: '已签' },
];

export const syncState = [
  { code: 0, value: '未同步' },
  { code: 1, value: '已同步' },
];

export const isVIP = [
  { code: 0, value: '否' },
  { code: 1, value: '是' },
]

export const partnerUnit = {
  unit: {
    key: 'unit',
    value: '万元',
    unitList: [
      { name: '万元', value: '万元' }
    ]
  }
}

export const sexType = [
  { name: '未知的性别', value: '0', },
  { name: '男', value: '1' },
  { name: '女', value: '2' },
  { name: '未说明的性别', value: '9' },
]

export const ethnicType = [
  { name: '汉族', value: '01', },
  { name: '蒙古族', value: '02' },
  { name: '回族', value: '03' },
  { name: '藏族', value: '04' },
  { name: '维吾尔族', value: '05', },
  { name: '苗族', value: '06' },
  { name: '彝族', value: '07' },
  { name: '壮族', value: '08' },
  { name: '布依族', value: '09', },
  { name: '朝鲜族', value: '10' },
  { name: '满族', value: '11' },
  { name: '侗族', value: '12' },
  { name: '瑶族', value: '13', },
  { name: '白族', value: '14' },
  { name: '土家族', value: '15' },
  { name: '哈尼族', value: '16' },
  { name: '哈萨克族', value: '17', },
  { name: '傣族', value: '18' },
  { name: '黎族', value: '19' },
  { name: '傈僳族', value: '20' },
  { name: '佤族', value: '21', },
  { name: '畲族', value: '22' },
  { name: '高山族', value: '23' },
  { name: '拉祜族', value: '24' },
  { name: '水族', value: '25', },
  { name: '东乡族', value: '26' },
  { name: '纳西族', value: '27' },
  { name: '景颇族', value: '28' },
  { name: '柯尔克孜族', value: '29', },
  { name: '土族', value: '30' },
  { name: '达斡尔族', value: '31' },
  { name: '仫佬族', value: '32' },
  { name: '羌族', value: '33', },
  { name: '布朗族', value: '34' },
  { name: '撒拉族', value: '35' },
  { name: '毛南族', value: '36' },
  { name: '仡佬族', value: '37', },
  { name: '锡伯族', value: '38' },
  { name: '阿昌族', value: '39' },
  { name: '普米族', value: '40' },
  { name: '塔吉克族', value: '41', },
  { name: '怒族', value: '42' },
  { name: '乌孜别克族', value: '43' },
  { name: '俄罗斯族', value: '44' },
  { name: '鄂温克族', value: '45', },
  { name: '德昂族', value: '46' },
  { name: '保安族', value: '47' },
  { name: '裕固族', value: '48' },
  { name: '京族', value: '49', },
  { name: '塔塔尔族', value: '50' },
  { name: '独龙族', value: '51' },
  { name: '鄂伦春族', value: '52' },
  { name: '赫哲族', value: '53', },
  { name: '门巴族', value: '54' },
  { name: '珞巴族', value: '55' },
  { name: '基诺族', value: '56' }
]

export const logType = [
  { value: 2, name: '登录日志' },
  { value: 1, name: '操作日志' }
];
